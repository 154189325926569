<template>
  <div class="main-content">
    <h1 :style="isMobile ? 'font-size:2em;' : 'font-size: 3em;'">
      {{ $t("News") }}
    </h1>

    <div class="cards-container mb-0 pb-0">
      <!--Carousel-->
      <div class="content_width h-100" style="flex: 0 1 65%; flex-grow: 3">
        <div class="h-100" style="border: 1px solid #c3c3c3">
          <CardNews
            :communications="communications"
            :newsLoading="newsLoading"
          />
        </div>
      </div>
      <!--List cards-->
      <!-- <div style="flex: 0 1 35%; flex-grow: 1">
        <div class="news-cards">
          <ContentLoader
            v-if="loading"
            :speed="2"
            primaryColor="rgb(230, 230, 230)"
            secondaryColor="rgb(240, 240, 240)"
            class="mt-5 p-2"
            style="margin-top: 80px"
          ></ContentLoader>
          <router-link
            v-else
            v-for="news in communications"
            :to="`news/${news._id}`"
            :key="news._id"
            class="news-card-container mt-1 mb-3"
          >
            <CCard class="news-card">
              <div class="card_content">
                <div class="news-card-img">
                  <img
                    v-if="news.image"
                    :src="getImageSrc(news.image)"
                    alt="News Image"
                  />
                </div>
                <div class="news-card-content">
                  <h4>{{ news.comm_name }}</h4>
                  <div>
                    <span class="news-description">{{ news.description }}</span>
                  </div>
                </div>
              </div>
            </CCard>
          </router-link>
        </div>
      </div> -->
      <CCard
        class="card_event h-100"
        style="overflow: hidden; border: 1px solid #c3c3c3"
      >
        <CCardBody
          class="d-flex flex-column card-event-body"
          :style="[
            'gap: 12px',
            'overflow: hidden',
            communications.length > 0 ? 'height: 70dvh' : '',
          ]"
        >
          <div class="card--event--container">
            <div v-if="loading">
              <el-skeleton
                :rows="5"
                style="display: flex; flex-direction: column; gap: 15px"
                animated
              >
                <template #template>
                  <div
                    v-for="(item, index) in 12"
                    :key="index"
                    style="height: 100px; display: flex; gap: 7px; padding: 5px"
                  >
                    <el-skeleton-item
                      variant="image"
                      style="width: 30%; height: 100%"
                    />
                    <div
                      style="
                        width: 70%;
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                      "
                    >
                      <el-skeleton-item variant="text" style="width: 30%" />
                      <div>
                        <el-skeleton-item variant="text" style="width: 100%" />
                        <el-skeleton-item variant="text" style="width: 100%" />
                        <el-skeleton-item variant="text" style="width: 100%" />
                      </div>
                    </div>
                  </div>
                </template>
              </el-skeleton>
            </div>
            <!-- Actual Event Cards -->
            <div
              v-else-if="communications.length"
              class="d-flex flex-column"
              style="gap: 5px; display: none"
            >
              <router-link
                v-for="news in communications"
                :to="`news/${news.id}`"
                :key="news.id"
                class="news-card-container"
              >
                <CCard class="news-card">
                  <div class="card_content">
                    <div class="news-card-img">
                      <img :src="getImageSrc(news.image_id)" alt="News Image" />
                    </div>
                    <div class="news-card-content">
                      <h4>{{ news.comm_name }}</h4>
                      <div>
                        <span class="news-description">{{
                          news.description.replace(/<[^>]*>/g, "").slice(0, 40)
                        }}</span>
                      </div>
                    </div>
                  </div>
                </CCard>
              </router-link>
            </div>

            <!-- Message when no events are available -->
            <div v-if="communications.length == 0 && !loading">
              <!-- <p>{{ $t("No events available") }}.</p> -->
              <el-empty
                :description="$t('No events available')"
                style="margin: auto"
                class="empty-image"
              />
            </div>
          </div>
        </CCardBody>
      </CCard>
      <div class="news-cards_mobile">
        <ContentLoader
          v-if="loading"
          :speed="2"
          primaryColor="rgb(230, 230, 230)"
          secondaryColor="rgb(240, 240, 240)"
          class="mt-5 p-2"
          style="margin-top: 80px"
        ></ContentLoader>
        <router-link
          v-else
          v-for="news in communications"
          :to="`news/${news._id}`"
          :key="news._id"
          class="news-card-container mt-2"
        >
          <CCard class="news-card_mobile">
            <div class="news-card-img_mobile h-50">
              <img
                v-if="news.image"
                :src="getImageSrc(news.image_id)"
                alt="News Image"
              />
            </div>
            <div class="h-50">
              <h5 class="news-card-content_mobile">{{ news.comm_name }}</h5>
              <p class="news-description_mobile">
                {{ news.description.replace(/<[^>]*>/g, "") }}
              </p>
            </div>
          </CCard>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import DeleteCommunicationModal from "./components/DeleteCommunicationModal.vue";
import AddEditCommunication from "./components/AddEditCommunication.vue";
import { CommunicationService } from "@/services/communications";
import { ContentLoader } from "vue-content-loader";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import { mapState } from "vuex";
import { showMessage } from "../../utils/helpers";
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import { ArrowLeft, ArrowRight } from "@element-plus/icons-vue";
import { useGlobalStore } from "../../stores/store";
import { useUserStore } from "@/stores/UserStore";
import { mapWritableState } from "pinia";
import { ElLoading } from "element-plus";
import CardNews from "../home/components/content/CardNews.vue";
import { NewsService } from "@/services/news";

export default {
  name: "Communication",
  components: {
    ArrowLeft,
    ArrowRight,
    Pagination,
    Slide,
    Navigation,
    DeleteCommunicationModal,
    AddEditCommunication,
    ContentLoader,
    OrganizationSelector,
    Carousel,
    CardNews,
  },
  data() {
    return {
      userStore: useUserStore(),
      globalStore: useGlobalStore(),
      showOptions: false,
      loading: false,
      communicationName: "",
      communicationDescription: "",
      dueDate: "",
      communications: [],
      displayedItems: 0,
    };
  },

  created() {
    this.getCommunicationList();
    this.displayedItems = Math.min(this.perPage, this.communications.length);
  },
  computed: {
    ...mapState({
      selectedEvent: (state) => state.selectedEvent,
      selectedTraining: (state) => state.selectedTraining,
      userData: (state) => state.userData,
      currentOrganization: (state) => state.selectedOrg,
      currentOrganizationObj: (state) => state.orgObj,
      currentProject: (state) => state.selectedProject,
    }),
    totalPages() {
      return Math.ceil(this.communications.length / this.perPage);
    },
    wrapAround() {
      return this.communications.length > 1;
    },
    activeCommunications() {
      return this.communications.filter((comm) => comm.active);
    },
    autoplayValue() {
      return this.communications.length > 0 ? 3000 : false;
    },
    ...mapWritableState(useGlobalStore, {
      isMobile: "isMobile",
    }),
  },
  watch: {
    // Watch the $route object for changes
    $route: "fetchData",
  },

  methods: {
    next() {
      this.$refs.carousel.next();
    },
    prev() {
      this.$refs.carousel.prev();
    },
    async refreshEverything() {
      this.getCommunicationList();
    },
    truncateString(str) {
      if (str.length > 50) {
        return str.substring(0, 50) + "...";
      } else {
        return str;
      }
    },
    getImageSrc(image_id) {
      console.log("image_id", image_id);
      const myBlobToken = this.userStore.blobToken;
      return `https://stmzgdsengage.blob.core.windows.net/news/${image_id}?${myBlobToken}`;
    },
    async getCommunicationList() {
      const loading = ElLoading.service({
        fullscreen: true,
        background: "#0a1f8f45",
      });
      try {
        // this.loading = true;

        const { data } = await NewsService.get_news({
          page: 1,
          count: 10,
        });
        this.communications = data.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        this.communications = this.communications.filter((comm) => {
          return (
            comm.users && comm.active && comm.users.includes(this.userData.id)
          );
        });
      } catch (error) {
        console.error("Error fetching News data:", error);
      } finally {
        // this.loading = false;
        loading.close();
      }
    },
  },
};
</script>
<style scoped>
.card_event {
  overflow-y: scroll;
}
.event-card {
  position: relative;
  height: 62px;
  min-width: 100%;
}
.event-card::before {
  content: "";
  height: 100%;
  width: 4px;
  background-color: #3399ff;
  left: 0;
  top: 0;
  position: absolute;
}
.card--event--container {
  height: 100dvh;
  overflow: auto;
}

@media (max-height: 850px) {
  .card-event-body {
    height: 10px;
  }
}
.card-event-body {
  gap: 12px;
  overflow: hidden;
}
@media (max-height: 679px) {
  .card--event--container,
  .card-event-body {
    height: 29dvh !important;
  }
}
@media (min-height: 900px) {
  .card--event--container,
  .card-event-body {
    height: 100dvh !important;
  }
}
.router-link {
  text-decoration: none !important;
  color: #434343; /* Change to your desired color */
}
.news-card-content_mobile {
  font-weight: bold;
}
.nav_pag {
  border-top: 1px solid #c3c3c3;
  padding: 24px;
}
.news-card-content {
  width: 80%;
}
.card_content {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 8px;
}
.news_container {
  display: flex;
  gap: 13px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}
.nav_weight {
  font-weight: 700 !important;
}
.carousel_card {
  /* border: 1px solid #c3c3c3; */
  background-color: white;
  flex: 1;
}

.news-content {
  display: flex;
  gap: 16px;
}

.news-cards {
  background-color: white;
  border: 1px solid #c3c3c3;
  display: flex;
  gap: 9px;
  flex-direction: column;
  height: calc(100vh - 200px);
  overflow-y: scroll;
}
.card_event {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: white;
  display: flex;
  flex-direction: column;
  /* padding: 10px; */
  /* max-width: 500px;
  min-width: 300px; */
  flex-grow: 1;
  box-sizing: border-box;
  width: 35%;
  /* height: 100%; */
  margin-bottom: 0;
}
.news-card {
  border: none !important;
  width: 100%;
  height: 100px;
  border: 1px solid #c3c3c3;

  margin-top: 5px;
  padding: 5px;
  background: transparent;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  text-decoration: none;
  color: #434343;
}
.news-cards_mobile {
  display: none;
}
.news-card_mobile {
  border: 1px solid #c3c3c3;
  width: 100% !important;
  height: 350px;
  padding: 20px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 12px;
  cursor: pointer;
  text-decoration: none;
  color: #434343;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}
.news-card-img_mobile {
  height: 200px;
}
.news-card-img_mobile img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.news-card-container:hover {
  text-decoration: none;
  transform: scale(0.98); /* Increase size on hover */
  transition: transform 0.3s ease;
}
.news-card-img {
  height: 100%;
  width: 30%;
}
.news-card-img img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.card {
  margin: 0;
}
.cards-container {
  display: flex;
  gap: 10px;
  /* padding: 15px; */
  height: calc(100vh - 200px);
}

.news-card h6 {
  height: 30px;
  overflow: hidden;
  margin-bottom: 0;
}
.news-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.news-description_mobile {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 0;
}
.carousel__item {
  padding: 15px 0 !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: calc(100vh - 300px) !important;
}
.carousel__slide {
  width: 100%;
}
.carousel {
  text-align: left;
}
.carousel__next .carousel__icon svg,
.carousel__prev .carousel__icon svg {
  fill: white; /* Set button icons' fill color to white */
}

.carousel__pagination-button::after {
  border-radius: 50%;
  width: 13px;
  height: 13px;
  background-color: white;
  border: 2px solid #0071ce;
}
.carousel__pagination-button:hover::after,
.carousel__pagination-button--active::after {
  background-color: #0071ce;
}
.carousel__pagination {
  margin: 10px 30px 10px 0px;
}
button:focus {
  outline: none;
}
.image-container {
  max-width: 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
}
.communication-image {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

.card-news {
  width: 100%;
  background-color: white;
}
.carousel-info {
  /* position: absolute;
  top: 2%;
  left: 7%;
  color: #ffffff;
  z-index: 2; */
}
.mobileMainContentArea {
  height: unset;
  overflow: auto;
}
.main-content {
  /* max-height: calc(100% - 120px); */
  overflow-y: auto;
  margin: auto;
  padding-right: 8px;
  height: 100%;
}

@media (max-width: 768px) {
  .main-content {
    padding: 0px;
  }
}

@media (max-width: 1133px) {
  .main-content {
    /* max-height: calc(100vh - 180px); */
  }
}
@media (max-width: 970px) {
  .main-content {
    /* margin-top: 60px; */
    max-height: 1000vh;
    overflow: auto;
  }
}

@media (max-width: 757px) {
  .news-content {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 603px) {
  .inputs-news {
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 522px) {
  .news-cards {
    flex-direction: column;
  }
  .news-card {
    width: 100%;
  }
  .news-card-img {
    height: 120px;
  }
  .news-card-img img {
    object-fit: cover;
    height: 100px;
    width: 100%;
  }
}

@media (max-width: 974px) {
  .cards-container {
    flex-direction: column;
    height: auto;
  }
}
@media (max-width: 820px) {
  .team-level {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 766px) {
  .cards-container {
    display: flex;
    flex-direction: column;
  }
  .content_width {
    width: 100%;
    /* margin-bottom: 50px; */
  }
  .card-event {
    width: 100%;
  }
}
@media (max-width: 831px) {
  .cards-container {
    height: auto;
  }
}
@media (max-width: 970px) {
  .news_container_layout {
    margin-top: 50px;
  }
}
@media (max-width: 650px) {
  .news_container_layout {
    margin-top: 0;
  }
}
@media (max-width: 932px) {
  .news-card-content {
    margin-left: 0;
    margin-top: 10px;
  }
  .card_content {
    flex-direction: column;
  }
  .news-cards {
    /* flex-direction: row !important;
    height: calc(100vh - 500px);
    overflow-x: scroll;
    width: 100% !important; */
    display: none;
  }
  .card_event {
    display: none;
  }
  .news-cards_mobile {
    display: flex;
    flex-direction: column;
    /* gap: 7px; */
    /* flex-wrap: wrap; */
    /* justify-content: center; */
  }
  .news-card_mobile {
    width: 22.4rem;
  }
  .news_container {
    flex-direction: column;
  }
}

@media (max-width: 917px) {
  .carousel_card {
    width: 100%;
    height: 350px;
    margin: 0 auto;
  }
  .carousel__viewport {
    max-height: 300px !important;
  }
  .nav_pag {
    padding: 0;
  }
}
</style>
